import { render, staticRenderFns } from "./Etiquetas.vue?vue&type=template&id=6f5e9ff2&scoped=true&"
import script from "./Etiquetas.vue?vue&type=script&lang=js&"
export * from "./Etiquetas.vue?vue&type=script&lang=js&"
import style0 from "./Etiquetas.vue?vue&type=style&index=0&id=6f5e9ff2&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f5e9ff2",
  null
  
)

export default component.exports