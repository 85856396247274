<template>
    <div>
        <div class="row mb-3">
            <div class="col-6">
                <h2 class="text-dark">
                    Etiquetas
                </h2>
            </div>
            <div class="col-6 text-right">
                <button class="btn btn-success mr-1" @click="add()">
                    <font-awesome-icon icon="plus" /> Crear
                </button>
            </div>
		</div>
        <div class="table-responsive">
            <vuetable ref="vuetable"
                :fields="fields"
                :apiUrl="vueTableConfig.url"
                :per-page="vueTableConfig.perPage"
                :appendParams="vueTableConfig.appendParams"
                :css="vueTableConfig.css.table"
                @vuetable:pagination-data="onPaginationData"
                pagination-path=""
                :transform="transform"
            >
                <div slot="name-slot" slot-scope="item">
                    <div class="row">
                        <div class="col-3 col-md-2">
                            <span>{{item.rowData.name}}</span>
                        </div>
                        <div class="col-6 col-md-6">
                            <span :style="{ 'background-color': item.rowData.rgb_color }" class="spanColor"></span>
                        </div>
                    </div>
                </div>       
                <div slot="acciones-slot" slot-scope="item">
                    <b-dropdown id="dropdown-1" text="Acciones" variant="primary">
                        <a class="dropdown-item" href="#" @click.prevent="edit(item.rowData)">
                            <font-awesome-icon icon="edit" /> Editar
                        </a>
                        <b-dropdown-divider></b-dropdown-divider>
                            <a class="dropdown-item" @click="remove(item.rowData)">
                                <font-awesome-icon icon="trash-alt" /> Eliminar
                            </a>
                    </b-dropdown>
                </div>
            </vuetable>
		</div>
        <div class="pt-1 pb-3 float-right">
            <vuetable-pagination ref="pagination"
                :css="vueTableConfig.css.pagination"
                @vuetable-pagination:change-page="onChangePage"
            />
            <vuetable-pagination-info ref="paginationInfo" 
                :css="vueTableConfig.info.css"
                :infoTemplate="vueTableConfig.info.infoTemplate"
                :noDataTemplate="vueTableConfig.info.noDataTemplate"
            />
        </div>
        <confirm ref="confirm"></confirm>
        <ModalEtiquetasForm :show.sync="modal" @closed="closeModal" @entity="labelCreated" :id-to-edit-modal="idToEdit"/>
    </div>
</template>
<style scoped>
    .spanColor {
        content: "";
        border-radius: 6px;
        height: 20px;
        width: 60px;
        display: inline-block;
    }
</style>

<script>
    import vueTableMixin from "@/mixins/VueTableMixin";

    import { library } from '@fortawesome/fontawesome-svg-core';
    import { faPlus, faEdit, faTrashAlt, faUserCog, faBook} from '@fortawesome/free-solid-svg-icons';

    // import _ from "lodash";

    import Confirm from '../../components/vue-confirm/Confirm.vue';
    import ModalEtiquetasForm from './ModalEtiquetasForm.vue';

    library.add([faPlus, faEdit, faTrashAlt, faUserCog, faBook]);

    export default {
        name: 'Etiquetas',
        mixins: [
            vueTableMixin
        ],
        components: {
            Confirm,
            ModalEtiquetasForm
        },
        data: () => {
            return {
                modal: false,
                idToEdit: null,
                fields: [
                    {
                        title: 'Nombre',
                        name: "name-slot",
                        sortField: 'name'
                    },
                    {
                        title: '',
                        name: "acciones-slot"
                    }
                ]
            }
        },
        methods: {
            add() {
                this.modal = true;
            },
            edit(item) {
                console.log('item', item);
                this.modal = true;
                this.idToEdit = item.id
            },
            closeModal() {
                this.modal = false;
                this.idToEdit = null;
            },
            labelCreated(entity) {
                let self = this;
                console.log('labelCreated entity', entity);

                self.refresh();
            },
            remove ( item ) {
                let self = this;
                console.log('item to remove', item.id);

                self.$refs.confirm.show({
                    html: true,
                    message: `¿ Está seguro de eliminar la etiqueta <b>${item.name}</b> del sistema ?`,
                    okButton: 'Eliminar',
                    callback: confirm => {
                        if (confirm) {
                            self.$refs.confirm.setLoading(true);
                            self.axios
                            .delete(`labels/${item.id}`)
                            .then(response => {
                                self.$refs.confirm.setLoading(false);
                                if(response.error) {
                                    self.$toastr.e(response.message, "Error");
                                } else {
                                    self.$toastr.s(response.message, "Correcto");
                                    self.$refs.confirm.close();
                                    self.refresh();
                                }
                            })
                            .catch(function (error) {
                                self.$refs.confirm.setLoading(false);
                                self.$toastr.e(error, "Exception");
                            });
                        }
                    }
                });
            }
        },
        beforeMount: function() {
            let self = this;

            console.log('aaaa',  `${self.$apiAdress}branches`);
            self.vueTableConfig.url = `${self.$apiAdress}labels`;
        }
    }
</script>
